import React, { FunctionComponent } from 'react'
import Helmet from "react-helmet";
import { IS_DEV } from "../utils/env";

export const Infringement: FunctionComponent = () => {
  let url = ""
  if (IS_DEV) {
    url = `http://${window.location.hostname}:3000/statics/侵权申诉指引表.docx`
  } else {
    url = `http://${window.location.hostname}/statics/侵权申诉指引表.docx`
  }

  return (
    <>
      <Helmet>
        <title>侵权申诉</title>
      </Helmet>

      <div className='infringement thin'>
        <p style={{ textAlign: "center" }}>侵权申诉指引</p>
        <br/>

        <p>欢迎使用Beat Saber玩家社区！</p>

        <p>Beat Saber玩家社区的创建是为了给玩家提供一个交流、分享、学习的社区，
          如您发现社区内包含侵犯您合法权益的作品，请根据以下指引进行申诉：</p>

        <p>1. 下载<a style={{ textDecoration: "none" }} target='_blank' href={url}>
          侵权申诉表</a>，
          填写申诉信息。（需由权利人或其合法授权的代理人亲笔签名，若为单位则需加盖单位公章）
        </p>

        <p>
          （1）权利人的姓名/名称、联系方式、地址、营业执照（单位）、身份证扫描件（个人）。
          <br/>
          （2）权利人申诉内容及要求，包括要求删除、断开连接的内容的具体名称及所在页面的网络地址。
          <br/>
          （3）证明材料及文件，包括但不限于对作品享有版权或依法享有信息网络传播权的权属证明、商标权证书、专利权证书、
          作品首次公开发表或发行日期证明材料、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书等能证明权利人
          拥有相关权利的有效权属证明；以及对涉嫌侵权作品侵权事实的举证。
          <br/>
          （4）权利人保证。权利人须保证其在本申诉表中提供的信息及其他相关材料、文件及其取得真实、合法、有效。由于此
          申诉表及相关材料、文件引起的一切后果皆由权利人承担。权利人保证承担和赔偿因网络根据权利人的通知书而删除或
          者断开有关侵权视频的链接而给网站造成的任何损失。
        </p>

        <p>2. 将填写完毕的申诉表及有效证明文件的扫描件通过电子邮件方式发送至qq_beatsaberbbs@163.com。</p>
        <p>3. 一旦收到符合上述要求之通知，我们将在合理时间内对涉嫌侵权的作品进行审核和处理。</p>

        <br/>
        <br/>
        <p style={{ textAlign: "right" }}>Beat Saber玩家社区</p>
        <p style={{ textAlign: "right" }}>2020年1月10日</p>
      </div>
    </>
  )
}
