import clsx from 'clsx'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { connect, MapStateToProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { downloadBeatmap, DownloadError } from '../../remote/download'
import { IState } from '../../store'
import {
  IAudioState,
  PreviewBeatmap,
  previewBeatmap as previewBeatmapFn,
  StopPreview,
  stopPreview as stopPreviewFn,
} from '../../store/audio'
import { parseCharacteristics } from '../../utils/characteristics'
import { formatDate } from '../../utils/formatDate'
import { Image } from '../Image'
import { DiffTags } from './DiffTags'
import { BeatmapStats } from './Statistics'
import Disc from "../../../images/disc.png";
import Sample from "../../../images/sample.jpg";
import Play from "../../../images/1_play.png";
import Stop from "../../../images/1_stop.png";


interface IConnectedProps {
  preview: IAudioState
}

interface IDispatchProps {
  previewBeatmap: PreviewBeatmap
  stopPreview: StopPreview
}

interface IPassedProps {
  map: IBeatmap
}

type IProps = IConnectedProps & IDispatchProps & IPassedProps
const BeatmapGridResult: FunctionComponent<IProps> = ({
  map,
  preview,
  previewBeatmap,
  stopPreview,
}) => {
  console.log(map.name, map.downloadURL)
  const [dateStr, setDateStr] = useState<string>(formatDate(map.uploaded))
  useEffect(() => {
    const i = setInterval(() => {
      const newStr = formatDate(map.uploaded)
      if (dateStr !== newStr) setDateStr(newStr)
    }, 1000 * 30)

    return () => clearInterval(i)
  }, [])

  const [hover, setHover] = useState(false)
  const toggleHover = () => {
    setHover(!hover)
  }

  const [inViewRef, inView] = useInView({ rootMargin: '240px' })
  if (!inView) {
    return (
      <div ref={inViewRef} className='beatmap-grid-result-hidden' id={map.key} />
    )
  }

  const inPreview = preview.loading && preview.key === map.key ||
    preview.state === 'playing' && preview.key === map.key
  const show = hover || inPreview
  const pic = inPreview ? Stop : Play

  return (
    <div className='beatmap-grid-result' id={map.key} ref={inViewRef}>
      <a href={`/beatmap/${map.key}`}>
        <div className='beatmap-content-container'>
          <img src={Disc} alt='唱片icon'/>
          <div className='beatmap-info-container' onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
            <div className='crazy-cover-back'>
              <Image
                src={map.coverURL}
                alt={''}
                draggable={false}
              />
              <div className='backdrop'/>
              <div className='mask'/>
            </div>
            <img className='crazy-cover-top'
                 src={map.coverURL}
                 alt={`Artwork for ${map.name}`}
            />
            <DiffTags
              style={{}}
              easy={map.metadata.difficulties.easy}
              normal={map.metadata.difficulties.normal}
              hard={map.metadata.difficulties.hard}
              expert={map.metadata.difficulties.expert}
              expertPlus={map.metadata.difficulties.expertPlus}
            />
             <img id="play-icon"
               className={clsx(
                 !show && 'hidden',
                 preview.loading && preview.key === map.key && 'loading',
                 preview.loading && 'disabled'
               )}
               onClick={e => {
                 e.preventDefault()

                 if (preview.state === 'playing' && preview.key === map.key) {
                   stopPreview()
                 } else {
                   previewBeatmap(map)
                 }
               }}
                src={pic}
                alt={''}
                draggable={false}
              />
            <div className='bottom-container-1'>
              <div className='beatmap-name'>{map.name}</div>
              <div className='uploader-name'>
                <i className='fas fa-user' style={{marginRight: '8px'}} aria-hidden="true"/>
                <span>{map.uploader.username}</span>
              </div>
            </div>
            <div className={clsx(!show && 'hidden', 'bottom-container-2')}>
              <div className='beatmap-name'>{map.name}</div>
              <div className='tags'>{'标签：' + map.tags}</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

const mapStateToProps: MapStateToProps<
  IConnectedProps,
  IPassedProps,
  IState
> = state => ({
  preview: state.audio,
})

const mapDispatchToProps: IDispatchProps = {
  previewBeatmap: previewBeatmapFn,
  stopPreview: stopPreviewFn,
}

const ConnectedBeatmapResult = connect(
  mapStateToProps,
  mapDispatchToProps
)(BeatmapGridResult)

export { ConnectedBeatmapResult as BeatmapResult }
