export enum UserActionTypes {
  SET_USER = '@@user/SET_USER',
  SET_WXQRCODE = '@@user/SET_WXQRCODE'
}

export interface IUser {
  _id: string
  username: string

  verified: boolean
  admin: boolean
  openid: string
  unionid: string
  headimgurl: string

  links: {
    steam?: string
    oculus?: string
  }

  platform: string
}

export interface IUserState {
  login: IUser | null | undefined
}
