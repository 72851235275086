import React, { FunctionComponent, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { history } from '../../init'
import { IScroller } from '../../store/scrollers'
import { checkHash } from '../../utils/scroll'
import { APIError } from '../APIError'
import { Loader } from '../Loader'
import { BeatmapMyResult } from './BeatmapMyResult'

interface IProps {
  scroller: IScroller

  finite: boolean | undefined
  fallback?: JSX.Element
  next: () => any
}

export const BeatmapMyScroller: FunctionComponent<IProps> = ({
 scroller: {maps, loading, done, error, type, status},

 finite,
 fallback,
 next,
}) => {
  const [ref, inView] = useInView({rootMargin: '240px'})
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (maps.length === 0) return
    if (scrolled) return
    if (history.action !== 'POP') return

    setScrolled(true)
    checkHash()
  }, [maps.length])

  if (inView && !loading && !done && !finite) next()
  if (error) return <APIError error={error}/>

  const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return (
    <>
      <div className="list">
        <div className="beatmap-my-result">
          <div className="fix-width"></div>
          <div>名称</div>
          <div>上传时间</div>
          <div>难度</div>
          <div>下载数</div>
          <div>喜欢度</div>
          <div>播放数</div>
          <div>举报</div>
          <div>标签</div>
          <div></div>
        </div>

        {maps.length === 0
          ? fallback || null
          : maps.map(m => <BeatmapMyResult key={m._id} map={m}/>)}

        {!loading || done ? null : <Loader/>}

        {!loading && !done && maps.length > 0 ? (
          <div ref={finite ? undefined : ref} style={{ height: '1px' }}/>
        ) : null}

        {!finite ? null : (
          <button
            className='button is-fullwidth'
            style={{ maxWidth: '180px', margin: '0 auto' }}
            onClick={() => next()}
          >
            Show More...
          </button>
        )}
      </div>
    </>
  )
}
