import React, {FunctionComponent, MouseEvent, useRef} from 'react'
import ReactPlayer from 'react-player'
import Logo from "../../images/logo2.png";
import {downloadInstaller} from "../remote/download";

interface IProps {
  seq: string
  line1: string
  line2: string
}

const steps: IProps[] = [
  {seq: "01", line1: "下载安装器", line2: "点击上面的按钮下载安装器，然后运行它！"},
  {seq: "02", line1: "筛选谱面", line2: "在谱面库中筛选自己喜欢的谱面！"},
  {seq: "03", line1: "下载谱面", line2: "一键安装自己喜欢的谱面，然后开始游戏吧！"}
]

const Stepper: FunctionComponent<IProps> = ({seq, line1, line2}) => (
  <div className="seq-container">
    <p className="seq">{seq}</p>
    <div className="lines-container">
      <p className="line1">{line1}</p>
      <p className="line2">{line2}</p>
    </div>
  </div>
)


export const Index: FunctionComponent = () => {
  window.location.href = "/browse/latest"
  
  const onDownloadClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    let url = ""
    if (window.location.hostname === "beatsaberbbs.com") {
      url = `https://${window.location.hostname}/zip/online/mod/BeatSaberModManager.zip`
    } else {
      url = `https://${window.location.hostname}/zip/dev/mod/BeatSaberModManager.zip`
    }
    downloadInstaller(url)
  }

  const coverUrl = `https://bsmod.fp.ps.netease.com/file/5e0ea55cfb758ad2b375fb444vzrOxzm02`
  const videoRef = useRef<ReactPlayer | null>(null)
  const onEnded = () => {
    console.log('onEnded')
    if (!videoRef.current) return
    // @ts-ignore
    videoRef.current.showPreview()
  }

  return (
    <>
      <div className="head-container">
        <div className='player-wrapper-as-bg'>
          <ReactPlayer playing loop muted
                       width='1920px' height='1080px'
                       url={`https://bsmod.fp.ps.netease.com/file/5e0ea56ffb758ad2b375fb4534QS3I2s02`}
          />
        </div>
        <div className='top-interactive-layer'>
          <div className='shit-design'>
            <img src={Logo} alt='节奏空间'/>
            <p className="sub-line">提供更丰富的游戏体验！</p>
          </div>
          <button className="download" onClick={e => onDownloadClick(e)}>下载安装器</button>

          <div className="steps-container">
            {steps.map(m => <Stepper key={m.seq} {...m}/>)}
          </div>
        </div>
      </div>


      <div className='div-outer'>
        <div className='player-wrapper'>
          <ReactPlayer className='react-player' ref={videoRef}
                       controls playing
                       light={coverUrl}
                       width='1198px' height='670px'
                       onEnded={onEnded}
                       url={`https://bsmod.fp.ps.netease.com/file/5e0ea581fb758ad2b375fb463lxO9dUN02`}
          />
        </div>
      </div>
    </>
  )
}
