import { saveAs } from 'file-saver'
import sanitize from 'sanitize-filename'
import { mutate } from 'swr'

export class DownloadError extends Error {
  public code: number

  constructor(message: string, code: number) {
    super(message)
    this.code = code
  }
}

export const downloadBeatmap = async (
  map: IBeatmap,
  mapStats: IMapStats,
  direct: boolean = false
) => {
  let downloadURL = direct ? map.directDownload : map.downloadURL
  const isHttps = 'https:' === document.location.protocol ? true: false;
  if(isHttps) {
    downloadURL = downloadURL.replace("http:", "https:")
  }else{
    downloadURL = downloadURL.replace("https:", "http:")
  }
  const resp = await fetch(downloadURL)

  mutate(`/stats/key/${map.key}`, {
    ...mapStats,
    ...map,
    stats: { ...map.stats, downloads: map.stats.downloads + 1 },
  })

  if (!resp.ok) throw new DownloadError('download failed', resp.status)
  const blob = await resp.blob()

  const songName = sanitize(map.metadata.songName)
  const authorName = sanitize(map.metadata.levelAuthorName)
  const filename = `${map.key} (${songName} - ${authorName}).zip`

  saveAs(blob, filename)
}

export const downloadInstaller = async (
  url: string
) => {
  const resp = await fetch(url);

  if (!resp.ok) throw new DownloadError('download failed', resp.status)
  const blob = await resp.blob()

  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = 'BeatSaberModManager.zip';
  a.click();
}

export const onceDownloadBeatmap = async (
  url: string
) => {
  const a = document.createElement('a')
  a.href = "beatsaberbbs://" + url
  a.click()
}
