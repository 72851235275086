import {AxiosError} from 'axios'
import React, {FunctionComponent, useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import {Link, RouteComponentProps} from 'react-router-dom'
import {BeatmapList} from '../components/Beatmap'
import {SearchStatus} from '../components/Beatmap/BeatmapAPI'
import {Loader} from '../components/Loader'
import {TextPage} from '../components/TextPage'
import {axios} from '../utils/axios'
import {NotFound} from './NotFound'

interface IParams {
  id: string
  status: string
}

interface ICounter {
  all: number
  notPassed: number
  passed: number
  underReview: number
}

export const Uploader: FunctionComponent<RouteComponentProps<IParams>> = (
  {
    match,
  }) => {
  const userID = match.params.id
  const statusTmp = (match.params.status === "all" ? "all" : +match.params.status) as SearchStatus

  const [user, setUser] = useState<IUserResponse | undefined | Error>(undefined)
  const [status, setStatus] = useState<SearchStatus>(statusTmp!== undefined?statusTmp:"all")

  const defaultCounter = {"all": 0, "notPassed": 0, "passed": 0, "underReview": 0,}
  const [counter, setCounter] = useState<ICounter>(defaultCounter)

  useEffect(() => {
    axios
      .get<IUserResponse>(`/users/find/${userID}`)
      .then(resp => {
        setUser(resp.data)
      })
      .catch(err => setUser(err))

    return () => {
      setUser(undefined)
    }
  }, [userID])

  useEffect(() => {
    axios
      .get<ICounter>(`/maps/uploader-count/${userID}`)
      .then(resp => {
        setCounter(resp.data)
      })
      .catch(err => setUser(err))

    return () => {
      setUser(undefined)
    }
  }, [userID])

  if (user === undefined) return <Loader/>
  if (user instanceof Error) {
    const error = user as AxiosError
    if (error.response && error.response.status === 404) {
      return <NotFound/>
    }

    return (
      <TextPage title='Network Error'>
        <p>Failed to load users' beatmap info! Please try again later.</p>
        <p>If the problem persists, please alert a site admin.</p>
      </TextPage>
    )
  }

  return (
    <>
      <Helmet>
        <title>Beatsaberbbs - Beatmaps by {user.username}</title>
      </Helmet>
      <div className="uploader">
        <div className="tab">
          <span className={status === "all" ? ("selected") : ("")}><Link to={`/uploader/${userID}/all`} onClick={e => {
            setStatus("all")
          }}> 全部谱面（{counter.all}）</Link></span>
          <span className={status === 0 ? ("selected") : ("")}><Link to={`/uploader/${userID}/0`} onClick={e => {
            setStatus(0)
          }}>审核中（{counter.underReview}）</Link></span>
          <span className={status === 1 ? ("selected") : ("")}><Link to={`/uploader/${userID}/1`} onClick={e => {
            setStatus(1)
          }}>已通过（{counter.passed}）</Link></span>
          <span className={status === 2 ? ("selected") : ("")}><Link to={`/uploader/${userID}/2`} onClick={e => {
            setStatus(2)
          }}>未通过（{counter.notPassed}）</Link></span>
        </div>
        <BeatmapList type='uploader' query={userID} status={status}/>
      </div>
    </>
  )
}
