import {AxiosError} from 'axios'
import clsx from 'clsx'
import {Push, push as pushFn, Replace, replace as replaceFn,} from 'connected-react-router'
import ms from 'ms'
import React, {FunctionComponent, useRef, useState} from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {Link} from "react-router-dom"
import stringReplace from 'react-string-replace'
import {FileInput} from '../components/FileInput'
import {Input, TextareaInput} from '../components/Input'
import {IState} from '../store'
import {IUser} from '../store/user'
import {axios} from '../utils/axios'
import swal from '../utils/swal'

interface IPassedProps {
  user: IUser | null | undefined
}

interface IDispatchProps {
  push: Push
  replace: Replace
}

type IProps = IPassedProps & IDispatchProps
const Upload: FunctionComponent<IProps> = ({user, push, replace}) => {
  if (user === null) {
    replace('/')
    return null
  } else if (user && user.verified === false) {
    return (
      <div className='thin'>
        <article className='message is-danger'>
          <div className='message-body'>
            <p>
              你的账号必须先通过邮件验证才能上传谱面，请查收邮件验证URL。
            </p>

            <p>邮件可能会被收入到垃圾邮件箱，请确认。</p>
          </div>
        </article>
      </div>
    )
  }

  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState('')
  const [titleErr, setTitleErr] = useState(undefined as string | undefined)

  const [file, setFile] = useState(null as File | null)
  const [fileErr, setFileErr] = useState(undefined as string | undefined)

  const [description, setDescription] = useState('')
  const [tags, setTags] = useState('')
  const [checkA, setCheckA] = useState(false)
  const commonProblems = useRef(null as HTMLDetailsElement | null)

  const checkAgreement = () => {
    setCheckA(true)
  }

  const submit = async () => {
    if (file === null) {
      setFileErr('你必须选择上传的谱面文件!')
      return
    }

    if (checkA === false) {
      setFileErr('上传文件你必须阅读并同意发布声明!')
      return
    }

    // const agreement = await swal.fire({
    //   confirmButtonText: 'Accept',
    //   html: <SwalContent />,
    //   reverseButtons: true,
    //   showCancelButton: true,
    //   title: '上传须知',
    //   type: 'warning',
    //   width: '38em',
    // })

    // if (agreement.value !== true) {
    //   setFileErr('上传文件你必须同意以下条款!')
    //   return
    // }

    const form = new FormData()
    form.set('name', title)
    form.set('description', description)
    form.set('tagStr', tags)
    form.set('beatmap', file)

    setLoading(true)
    try {
      const resp = await axios.post<IBeatmap>('/upload', form)

      setLoading(false)
      replace(`/uploader/${user?._id}/0`)
    } catch (err) {
      setLoading(false)
      const {response} = err as AxiosError<IFieldsError | IValidationError>
      if (response === undefined) {
        setFileErr('发生错误了，请稍后重新。')
        return
      }

      if (response.status === 503) {
        setTitleErr('上传文件已禁用，请稍后再试。')
        return
      }

      if (response.status === 403) {
        setTitleErr('你的账号必须通过邮箱验证!')
        return
      }

      if (response.status === 429) {
        const reset = response.headers['rate-limit-reset']
        const getTimeStr = () => {
          if (!reset) return 'a bit'

          const resetTime = new Date(parseInt(reset, 10) * 1000)
          const offset = resetTime.getTime() - Date.now()

          return ms(offset, {long: true})
        }

        setTitleErr(
          `你上传太快了， 请休息 ${getTimeStr()} 再试.`
        )

        return
      }

      const resp = response.data

      if (resp.identifier === 'ERR_SCHEMA_VALIDATION_FAILED') {
        swal.fire({
          html: <ValidationSwalContent {...resp} />,
          showCancelButton: false,
          title: 'Invalid Beatmap',
          type: 'error',
          width: '45em',
        })

        return setFileErr('谱面文件无效!')
      } else if (
        resp.identifier === 'ERR_INVALID_FIELDS' &&
        resp.fields !== undefined
      ) {
        if (resp.fields.some(x => x.path === 'name')) {
          setTitleErr('谱面名称无效!')
        } else {
          setFileErr(
            `谱面文件无效! 原因: ${resp.fields
              .map(x => x.path)
              .join(', ')}`
          )
          showProblems()
        }

        return
      }

      switch (resp.identifier as string) {
        case 'ERR_DUPLICATE_BEATMAP':
          return setFileErr('谱面已经存在!')

        case 'ERR_BEATMAP_INFO_NOT_FOUND':
          setFileErr('谱面不包含 info.dat 文件!')
          return showProblems()

        case 'ERR_BEATMAP_INFO_INVALID':
          setFileErr('谱面 info.dat 文件无效!')
          return showProblems()

        case 'ERR_BEATMAP_COVER_NOT_FOUND':
          setFileErr('谱面无封面图片!')
          return showProblems()

        case 'ERR_BEATMAP_COVER_INVALID':
          setFileErr('谱面封面图片无效!')
          return showProblems()

        case 'ERR_BEATMAP_COVER_NOT_SQUARE':
          setFileErr('谱面封面图片不是正方形尺寸!')
          return showProblems()

        case 'ERR_BEATMAP_COVER_TOO_SMOL':
          setFileErr('谱面封面图片至少是256x256px!')
          return showProblems()

        case 'ERR_BEATMAP_AUDIO_NOT_FOUND':
          setFileErr('谱面无音频文件!')
          return showProblems()

        case 'ERR_BEATMAP_AUDIO_INVALID':
          setFileErr('谱面音频文件无效!')
          return showProblems()

        case 'ERR_BEATMAP_DIFF_NOT_FOUND':
          setFileErr('含有多个不同的谱面文件!')
          return showProblems()

        case 'ERR_BEATMAP_CONTAINS_ILLEGAL_FILE':
          setFileErr('谱面zip包含了非法文件!')
          return showProblems()

        case 'ERR_BEATMAP_CONTAINS_AUTOSAVES':
          setFileErr('谱面zip包含了autosaves!')
          return showProblems()

        case 'ERR_BEATMAP_AUDIO_READ_FAILURE':
          return setFileErr('谱面音频文件无法解析!')

        case 'ERR_BEATMAP_PARSE_TIMEOUT':
          return setFileErr('谱面文件无法解析!')

        default:
          setFileErr('发生错误了，请稍后重新。')
      }
    }
  }

  const showProblems = () => {
    if (commonProblems.current !== null) {
      commonProblems.current.open = true
    }
  }

  return (
    <div className='thin'>
      <div className='upload'>
        <h1 className='has-text-centered is-size-3 has-text-weight-light'>
          谱面上传
        </h1>
        <br/>

        <Input
          label='谱面名称'
          value={title}
          placeholder='最多255个字符'
          errorLabel={titleErr}
          maxLength={255}
          autoFocus={true}
          onChange={v => setTitle(v)}
        />

        <TextareaInput
          label='谱面描述'
          value={description}
          placeholder=''
          onChange={v => setDescription(v)}
          maxLength={10000}
        />

        <Input
          label='谱面标签'
          value={tags}
          placeholder='自动填充或者手动覆盖，多个标签使用空格分隔'
          maxLength={255}
          onChange={v => setTags(v)}
        />

        <FileInput
          label='谱面ZIP文件 '
          errorLabel={fileErr}
          file={file}
          accept='application/zip, .bsl'
          onChange={f => {
            setFileErr(undefined)
            setFile(f)
          }}
        />

        <details className='details' ref={commonProblems} open>
          <summary>上传须知</summary>

          <div className='content'>
            <ul style={{marginTop: '4px'}}>
              <li>
                <b>谱面zip包必须使用最新 (2.0.0) 格式。</b>
              </li>
              <li>谱面zip包不能超过15MB</li>
              <li>谱面zip包的所有谱面文件必须放在根目录里</li>
              <li>
                谱面zip包不能包含autosaves或非谱面文件
              </li>
              <li>
                上传格式请参考 <a href='https://hfc1-bsmod.s3.nie.netease.com/online/upload/demo.rar' target='_blank'>样例</a>
              </li>
              <li>
                {/* tslint:disable-next-line:max-line-length */}
                详情请下载doc文档查看 <a href='https://hfc1-bsmod.s3.nie.netease.com/online/upload/上传歌曲须知.docx'
                                target='_blank'>上传须知</a>
              </li>
            </ul>
          </div>
        </details>

        <button
          className={clsx('button', 'is-fullwidth', loading && 'is-loading')}
          disabled={loading}
          onClick={() => submit()}
        >
          提交上传
        </button>
        <div className='agreement'>
          <input type="checkbox" value='1' onClick={() => checkAgreement()}/> 我已阅读并同意网站歌曲<Link
          to='/legal/release_agreement'>发布声明</Link>
        </div>
      </div>
    </div>
  )
}

/*
const SwalContent: FunctionComponent = () => (
  <div className='content'>
    <p>
      Please confirm that you have all the applicable rights to publish this
      beatmap, including but not limited to:
    </p>

    <ul style={{ textAlign: 'left' }}>
      <li>Music distribution rights</li>
      <li>Rights to the beatmap data (notes and lighting)</li>
      <li>
        Rights to publish any additional content included in the beatmap zip
      </li>
      <li>
        Permission to grant Beatsaberbbs distribution rights to all files contained
        within the beatmap zip
      </li>
    </ul>

    <p>
      By clicking accept, you agree to grant Beatsaberbbs rights to publish and
      distribute this beatmap and everything contained within the zip.
    </p>
  </div>
)
*/
interface IValidationProps {
  filename: string
  path: string | null
  message: string
}

const ValidationSwalContent: FunctionComponent<IValidationProps> = (
  {
    filename,
    path,
    message,
  }) => {
  const parsed = stringReplace(message, /`(.+)`/g, match => (
    <code>{match}</code>
  ))

  return (
    <div className='swal-validation-content'>
      <p>
        <b>
          Error in <code>{filename}</code>
        </b>
      </p>

      <p style={{fontSize: '0.9em'}}>
        {path === null ? (
          <>Root {parsed}.</>
        ) : (
          <>
            Field <code>{path}</code> {parsed}.
          </>
        )}
      </p>
    </div>
  )
}

const mapStateToProps: MapStateToProps<IProps, {}, IState> = state => ({
  user: state.user.login,

  push: pushFn,
  replace: replaceFn,
})

const mapDispatchToProps: IDispatchProps = {
  push: pushFn,
  replace: replaceFn,
}

const ConnectedUpload = connect(mapStateToProps, mapDispatchToProps)(Upload)

export {ConnectedUpload as Upload}
