import Axios from "axios"
import {push as pushFn} from "connected-react-router"
import React, {FunctionComponent, useEffect, useState} from "react"
import { connect, MapStateToProps } from 'react-redux'
import {Loader} from "../components/Loader"
import {TextPage} from "../components/TextPage"
import {WxCheckLogin, wxCheckLogin as wxCheckLoginFun} from "../store/user"
import {axios} from '../utils/axios'

interface IProps {
  show: boolean
}

export const MyWxLogin: FunctionComponent<IProps> = ({show}) => {
  const [signed, setSigned] = useState<boolean>(false)
  const [loadQrUrl, setLoadQrUrl] = useState<string | undefined | Error>("")
  const [timeoutSignal, setTimeoutSignal] = useState<boolean>(false)
  const randomString = Math.random().toString(36).substring(2, 15)
    + Math.random().toString(36).substring(2, 15)
    + Date.parse( new Date().getTime().toString())
  const loadQRCode = () => {
    const url = `https://vrservice.netvios.163.com/wechat/getQrcode?Authorization=mod-` + randomString
    console.info("=== url = ", url)
    Axios.create()
      .post(url)
      .then(resp => {
        console.log(resp.data)
        const data = resp.data
        if (data.code === "000000") {
          setLoadQrUrl(data.data)
        } else {
          console.error(data.msg)
        }
      })
      .catch(err => {
        console.error(err)
        setLoadQrUrl(err)
      })
  }

  useEffect(() => {
    loadQRCode()
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()
    let count = 0
    const interval = setInterval(() => {
      count++
      if (count > 45) {
        clearInterval(interval)
        setTimeoutSignal(true)
        setLoadQrUrl("")
        setSigned(false)
      } else {
        axios
          .get<ICheckWxLoginResponse>(`/auth/wx/checkwxlogin/${randomString}`,{
            cancelToken: source.token
          })
          .then(resp => {
            console.info(resp.data)
            if (resp.data.signed) {
              setSigned(resp.data.signed)
              clearInterval(interval)
              location.reload()
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
    }, 1000)

    return () => {
      setLoadQrUrl("")

      source.cancel("cancel get")
      clearInterval(interval)
    }
  }, [show])

  if (timeoutSignal) {
    return (
      <TextPage title='Timeout Error'>
        <p>QRCode Timeout! Please try again later.</p>
        <p>If the problem persists, please alert a site admin.</p>
      </TextPage>
    )
  }

  if (loadQrUrl === "") return <Loader/>
  if (loadQrUrl instanceof Error) {
    return (
      <TextPage title='Network Error'>
        <p>Failed to load QRCode info! Please try again later.</p>
        <p>If the problem persists, please alert a site admin.</p>
      </TextPage>
    )
  }

  return (
    <>
      {!loadQrUrl ? (
        "获取登录二维码失败，请再次刷新页面" +
        "loading..."
      ) : (
        <img src={loadQrUrl.toString()} alt='微信二维码' style={{width: '100%', height: '100%'}}/>
      )}
    </>
  )
}

// const mapDispatchToProps: IProps = {
//   show: true
// }
//
//
// const ConnectedWxLogin = connect(
//   null,
//   mapDispatchToProps
// )(WxLogin)

// export {ConnectedWxLogin as WxLogin}
