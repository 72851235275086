import {AxiosError} from 'axios'
import clsx from 'clsx'
import {Push, push as pushFn} from 'connected-react-router'
import React, {FunctionComponent, useState,} from 'react'
import Helmet from 'react-helmet'
import nl2br from 'react-nl2br'
import {connect, MapStateToProps} from 'react-redux'
import useSWR from 'swr'
import Disc from '../../../images/disc2.png'
import {downloadBeatmap, DownloadError, onceDownloadBeatmap} from '../../remote/download'
import {NotFound} from '../../routes/NotFound'
import {IState} from '../../store'
import {
  IAudioState,
  PreviewBeatmap,
  previewBeatmap as previewBeatmapFn,
  StopPreview,
  stopPreview as stopPreviewFn,
} from '../../store/audio'
import {IUser} from '../../store/user'
import {axiosSWR} from '../../utils/axios'
import {convertSecondsToTime, formatDate} from "../../utils/formatDate";
import {Loader} from '../Loader'
import {TextPage} from '../TextPage'

interface IConnectedProps {
  user: IUser | null | undefined
  preview: IAudioState
}

interface IDispatchProps {
  push: Push
  previewBeatmap: PreviewBeatmap
  stopPreview: StopPreview
}

interface IPassedProps {
  mapKey: string
}

type IProps = IConnectedProps & IDispatchProps & IPassedProps

enum PlayTypes {
  success_rate = '成功率',
  hits = '打击数',
  misses = 'Miss数',
  combs = '连击数'
}

interface IPlayDataItemOfDiff {
  [PlayTypes.success_rate]: string
  [PlayTypes.hits]: string
  [PlayTypes.misses]: string
  [PlayTypes.combs]: string
}

type Diffs = 'Easy' | 'Normal' | 'Hard' | 'Expert' | 'Expert+' | 'Invalid'

interface IPlayDataOfDiff {
  'Easy'?: IPlayDataItemOfDiff | null,
  'Normal'?: IPlayDataItemOfDiff | null,
  'Hard'?: IPlayDataItemOfDiff | null,
  'Expert'?: IPlayDataItemOfDiff | null,
  'Expert+'?: IPlayDataItemOfDiff | null,
  'Invalid'?: IPlayDataItemOfDiff | null,
}

const BeatmapDetail: FunctionComponent<IProps> = (
  {
    user,
    push,
    mapKey,
    preview,
    previewBeatmap,
    stopPreview,
  }) => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [diff, setDiff] = useState<Diffs>('Invalid')
  // console.log('BeatmapDetail')

  const {data: map, error: mapError} = useSWR<IBeatmap, AxiosError>(
    `/maps/detail/${mapKey}`,
    axiosSWR
  )

  const diffs: Diffs[] = []
  if (map) {
    if (map.metadata.difficulties.easy) {
      diffs.push('Easy')
    }
    if (map.metadata.difficulties.normal) {
      diffs.push('Normal')
    }
    if (map.metadata.difficulties.hard) {
      diffs.push('Hard')
    }
    if (map.metadata.difficulties.expert) {
      diffs.push('Expert')
    }
    if (map.metadata.difficulties.expertPlus) {
      diffs.push('Expert+')
    }
    // console.log(diffs)
    if (diff === 'Invalid') {
      setDiff(diffs[0])
    }
  }

  const {data: mapStats, error: statsError} = useSWR<IMapStats, AxiosError>(
    `/stats/key/${mapKey}`,
    axiosSWR
  )

  const tmp: IPlayDataOfDiff = {}
  const NA: IPlayDataItemOfDiff = {
    [PlayTypes.success_rate]: 'NA',
    [PlayTypes.hits]: 'NA',
    [PlayTypes.combs]: 'NA',
    [PlayTypes.misses]: 'NA'
  }
  tmp.Easy = NA
  tmp.Normal = NA
  tmp.Hard = NA
  tmp.Expert = NA
  tmp["Expert+"] = NA
  // const [playData, setPlayData] = useState<IPlayData>(tmp)
  const playDataOfDiff = tmp
  if (mapStats) {
    for (const playStats of mapStats.playStatsWithDiff) {
      const play: IPlayDataItemOfDiff = {
        [PlayTypes.success_rate]: Math.floor(playStats.accuracy / playStats.accuracyZoomOutTimes * 100) + '%',
        [PlayTypes.hits]: playStats.avgHits.toString(),
        [PlayTypes.combs]: playStats.avgCombos.toString(),
        [PlayTypes.misses]: playStats.avgMisses.toString()
      }

      if (playStats.difficulty === 'Easy') {
        playDataOfDiff.Easy = {...play}
      } else if (playStats.difficulty === 'Normal') {
        playDataOfDiff.Normal = {...play}
      } else if (playStats.difficulty === 'Hard') {
        playDataOfDiff.Hard = {...play}
      } else if (playStats.difficulty === 'Expert') {
        playDataOfDiff.Expert = {...play}
      } else if (playStats.difficulty === 'ExpertPlus') {
        playDataOfDiff["Expert+"] = {...play}
      } else {
        console.error('unknown playStats.difficulty: ' + playStats.difficulty)
      }
    }
    // stats.playRanks = stats.playRanks.concat(stats.playRanks)
    // stats.playRanks = stats.playRanks.concat(stats.playRanks)
  }

  if (mapError || statsError) {
    const error = (mapError || statsError) as AxiosError
    if (error.response && error.response.status === 404) {
      return <NotFound/>
    }

    return (
      <TextPage title='Network Error'>
        <p>Failed to load beatmap info! Please try again later.</p>
        <p>If the problem persists, please alert a site admin.</p>
      </TextPage>
    )
  }

  if (!map || !mapStats) return <Loader/>
  const isUploader = user && (user._id === map.uploader._id || user.admin)

  const onChangeDiff = (newDiff: Diffs) => {
    // console.info(diff, newDiff)
    if (diff === newDiff) {
      return;
    }
    setDiff(newDiff)
  }

  return (
    <div className='page-container'>
      <Helmet>
        <title>谱面 - {map.name}</title>
      </Helmet>

      <div className='beatmap-detail'>
        <div className='disc-container'>
          <img src={map.coverURL}
               alt=''
               draggable={false}
          />
          <img className='disc'
               src={Disc}
               alt=''
               draggable={false}
          />
        </div>
        <img className='cover'
             src={map.coverURL}
             alt=''
             draggable={false}
        />
        <div className='info-container'>
          <div className='mask-backdrop'/>
          <div className='info-blocks'>
            <p className='title'>{map.name}</p>
            <div className='uploader-name'>
              <i className='fas fa-user' style={{marginRight: '8px'}} aria-hidden="true"/>
              <span>{map.uploader.username}</span>
            </div>
            <p className='upload-time'
               title={new Date(map.uploaded).toISOString()}>{'上传时间：' + formatDate(map.uploaded)}
            </p>
            <p className='rank-time'
               title={new Date(map.uploaded).toISOString()}>{'Ranked时间：' + formatDate(map.uploaded)}
            </p>
            <p className='beatmap-length'>
              {'谱面时长：' + (map.metadata.duration ? convertSecondsToTime(map.metadata.duration) : '未知')}
            </p>

            <p className='desc'>
              {'描述信息：' + nl2br(map.description)}
            </p>
            <p className='tags'>
              {'标签：' + map.tags}
            </p>
            <div className='button-group'>
              <button
                disabled={(preview.loading && preview.key === map.key)}
                onClick={
                  e => {
                    e.preventDefault()
                    if (preview.state === 'playing' && preview.key === map.key) {
                      stopPreview()
                    } else {
                      previewBeatmap(map)
                    }
                  }}
              >
                {
                  (preview.loading && preview.key === map.key) ? '加载中' :
                    (preview.state === 'playing' && preview.key === map.key ? '停止' : '试听')
                }
              </button>
              <button onClick={e => {
                e.preventDefault()
                onceDownloadBeatmap(map.downloadURL)
              }}>一键安装
              </button>
              <button onClick={e => {
                e.preventDefault()
                downloadBeatmap(map, mapStats).catch((err: DownloadError) => {
                  alert(`Download Failed with code ${err.code}!`)
                })
              }}
              >下载
              </button>
            </div>
          </div>
        </div>
        <DiffsComponent activeDiff={diff} diffs={diffs} onChangeDiff={onChangeDiff}/>
        <PlayDatas diff={diff} playDataOfDiff={playDataOfDiff[diff] as IPlayDataItemOfDiff}/>
      </div>

      <div className='rank-container'>
        <div className="row header">
          <div className='item'>排名</div>
          <div className='item'>总分</div>
          <div className='item'>准确率</div>
          <div className="item extra">玩家昵称</div>
          <div className='item'>最大连击</div>
          <div className='item'>Miss数</div>
        </div>

        {
          (mapStats.playRanks && mapStats.playRanks.length !== 0) ? (
            mapStats.playRanks.map((rank, index) =>
              <div className="row data" key={index}>
                <div className='item'>{index + 1}</div>
                <div className='item'>{rank.score}</div>
                <div className='item'>{Math.floor(rank.accuracy / rank.accuracyZoomOutTimes * 100) + '%'}</div>
                <div className="item extra">{rank.nickname}</div>
                <div className='item'>{rank.maxCombo}</div>
                <div className='item'>{rank.cubeMissCount}</div>
              </div>
            )
          ) : (
            <div className='empty-records'>还没有任何记录</div>
          )
        }
      </div>
    </div>
  )
}


const mapStateToProps: MapStateToProps<IConnectedProps,
  IPassedProps,
  IState> = state => ({
  preview: state.audio,
  user: state.user.login,
})

const mapDispatchToProps: IDispatchProps = {
  previewBeatmap: previewBeatmapFn,
  push: pushFn,
  stopPreview: stopPreviewFn,
}

const ConnectedBeatmapDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(BeatmapDetail)
export {ConnectedBeatmapDetail as BeatmapDetail}


interface IDiffsProps {
  activeDiff: Diffs
  diffs: Diffs[]
  onChangeDiff: (diff: Diffs) => void
}

const DiffsComponent: FunctionComponent<IDiffsProps> = ({activeDiff, diffs, onChangeDiff}) => {
  return (
    <div className='diffs-container'>
      {diffs.map((diff: Diffs) => (
        <button key={diff}
                className={clsx(diff === activeDiff && 'active')}
                onClick={
                  e => {
                    e.preventDefault()
                    onChangeDiff(diff)
                  }}
        >
          {diff}
        </button>
      ))}
    </div>
  )
}

interface IPlayDataOfDiffProps {
  diff: string
  playDataOfDiff: IPlayDataItemOfDiff
}

const PlayData: FunctionComponent<{ name: string, value: string }> = ({name, value}) => {
  return (
    <div className='play-info-item'>
      <div className='inner-container'>
        <p className="value">{value}</p>
        <p className='name'>{name}</p>
      </div>
    </div>
  )
}

const PlayDatas: FunctionComponent<IPlayDataOfDiffProps> = ({diff, playDataOfDiff}) => {
  return (
    <>
      <div className='play-info-container'>
        <PlayData value={playDataOfDiff[PlayTypes.success_rate]} name={PlayTypes.success_rate}/>
        <PlayData value={playDataOfDiff[PlayTypes.hits]} name={PlayTypes.hits}/>
        <PlayData value={playDataOfDiff[PlayTypes.misses]} name={PlayTypes.misses}/>
        <PlayData value={playDataOfDiff[PlayTypes.combs]} name={PlayTypes.combs}/>
      </div>
    </>
  )
}
