import {Push, push as pushFn} from "connected-react-router"
import React, {FunctionComponent, MouseEvent, useEffect, useState} from 'react'
import {useInView} from 'react-intersection-observer'
import {connect, MapStateToProps} from 'react-redux'
import {Link} from 'react-router-dom'
import {IState} from '../../store'
import {
  IAudioState,
  PreviewBeatmap,
  previewBeatmap as previewBeatmapFn,
  StopPreview,
  stopPreview as stopPreviewFn,
} from '../../store/audio'
import {IUser} from "../../store/user"
import {axios} from "../../utils/axios"
import {formatDate} from '../../utils/formatDate'
import swal from "../../utils/swal"
import {Image} from '../Image'
import {DiffTags} from './DiffTags'
import {formatUrl} from "../../utils/formatUrl"

interface IConnectedProps {
  preview: IAudioState
  user: IUser | null | undefined
}

interface IDispatchProps {
  push: Push
  previewBeatmap: PreviewBeatmap
  stopPreview: StopPreview
}

interface IPassedProps {
  map: IBeatmap
}

type IProps = IConnectedProps & IDispatchProps & IPassedProps
const BeatmapMyResult: FunctionComponent<IProps> = (
  {
    map,
    preview,
    previewBeatmap,
    stopPreview,
    user,
    push,
  }) => {
  const [dateStr, setDateStr] = useState<string>(formatDate(map.uploaded))

  useEffect(() => {
    const i = setInterval(() => {
      const newStr = formatDate(map.uploaded)
      if (dateStr !== newStr) setDateStr(newStr)
    }, 1000 * 30)

    return () => clearInterval(i)
  }, [])

  const [inViewRef, inView] = useInView({rootMargin: '240px'})
  if (!inView) {
    return (
      <div ref={inViewRef} className='beatmap-result-hidden' id={map.key}/>
    )
  }

  const isUploader = user && (user._id === map.uploader._id || user.admin)
  const deleteMap = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (!isUploader) return

    const result = await swal.fire({
      confirmButtonColor: '#bf2a42',
      reverseButtons: true,
      showCancelButton: true,
      text: '你确定删除么？此操作无法回退',
      title: '删除谱面',
      type: 'warning',
    })

    if (!result.value) return
    try {
      await axios.post(`/manage/delete/${map.key}`)
      // return push (`/uploader/${user?._id}/all`)
      window.location.reload() // todo change better way to refresh data operation
    } catch (err) {
      swal.fire({
        title: '删除谱面失败',
        type: 'error',
      })

      console.error(err)
    }
  }

  const reviewMap = async (e: MouseEvent<HTMLAnchorElement>, status:string) => {
    e.preventDefault()
    if (!isUploader) return

    try {
      await axios.post(`/manage/review/${map.key}/`+status)
      // return push (`/uploader/${user?._id}/all`)
      window.location.reload() // todo change better way to refresh data operation
    } catch (err) {
      swal.fire({
        title: '操作失败',
        type: 'error',
      })

      console.error(err)
    }
  }

  return (
    <div className='beatmap-my-result' id={map.key} ref={inViewRef}>
      <div className='cover'>
        <Link to={`/beatmap/${map.key}`}>
          <Image
            src={formatUrl(map.coverURL)}
            alt={`Artwork for ${map.name}`}
            draggable={false}
          />
        </Link>
      </div>
      <div>{map.name}</div>
      <div> {dateStr} </div>
      <DiffTags
        style={{marginBottom: 0}}
        easy={map.metadata.difficulties.easy}
        normal={map.metadata.difficulties.normal}
        hard={map.metadata.difficulties.hard}
        expert={map.metadata.difficulties.expert}
        expertPlus={map.metadata.difficulties.expertPlus}
      />
      <div> {map.stats.downloads} </div>
      <div> {map.stats.upVotes} </div>
      <div> {map.stats.plays} </div>
      <div> {map.status !== 4 ? "No" : "Yes"} </div>
      <div> {map.tags} </div>
      {user?.admin ? (
        <div className="right">
          {
            map.status === 2 || map.status === 0 ? (
              <a href="#" onClick={e => reviewMap(e, "1")}>通过</a>
            ) : null
          }
          {
            map.status === 1 || map.status === 0 ? (
              <a href="#" onClick={e => reviewMap(e, "2")}>不通过</a>
            ) : null
          }
          <a href="#" onClick={e => deleteMap(e)}>删除</a>
        </div>
      ) : (
        <div className="right">
          <a href="#">编辑</a>
          <a href="#" onClick={e => deleteMap(e)}>删除</a>
        </div>
      )}
    </div>
  )
}

const mapStateToProps: MapStateToProps<IConnectedProps,
  IPassedProps,
  IState> = state => ({
  preview: state.audio,
  user: state.user.login,
})

const mapDispatchToProps: IDispatchProps = {
  previewBeatmap: previewBeatmapFn,
  push: pushFn,
  stopPreview: stopPreviewFn,
}

const ConnectedBeatmapResult = connect(
  mapStateToProps,
  mapDispatchToProps
)(BeatmapMyResult)

export {ConnectedBeatmapResult as BeatmapMyResult}
