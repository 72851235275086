import React, { FunctionComponent } from 'react'

interface IDropdownProps {
  label: string
}

export const NavbarDropdown: FunctionComponent<IDropdownProps> = ({
  label,
  children,
}) => (
  <div className='navbar-item has-dropdown is-hoverable'>
    <a className='navbar-link'>{label}</a>
    <div className='navbar-dropdown'>{children}</div>
  </div>
)

interface IDropdownProps2 {
  url: string
}

export const NavbarDropdown2: FunctionComponent<IDropdownProps2> = ({
  url,
  children,
}) => (
  <div className='navbar-item has-dropdown is-hoverable'>
    <div>
      <img className='navbar-img' src={url} alt="Avatar"/>
    </div>
    <div className='navbar-dropdown'>{children}</div>
  </div>
)


export const NavbarDropdownDivider: FunctionComponent = () => (
  <hr className='navbar-divider' />
)
