import {FunctionComponent, useEffect} from 'react'
import {connect, MapStateToProps} from 'react-redux'
import {IState} from '../../store'
import {IScroller, RequestNextMaps, requestNextMaps as requestNextMapsFn} from '../../store/scrollers'

interface IRenderProps {
  scroller: IScroller
  next: () => any
}

interface ICommonProps {
  render: (props: IRenderProps) => JSX.Element
}

interface IConnectedProps {
  scroller: IScroller
}

interface IFunctionProps {
  requestNextMaps: RequestNextMaps
}

type IPassedProps = ICommonProps & IBeatmapSearch
type IProps = IPassedProps & IConnectedProps & IFunctionProps

const BeatmapAPI: FunctionComponent<IProps> = ({
    render,
    scroller,
    requestNextMaps,
  }) => {
  const request = () =>
    requestNextMaps(scroller.key, scroller.type, scroller.query, scroller.status)

  const next = () => {
    if (scroller.maps.length !== 0) request()
  }

  useEffect(() => {
    if (scroller.maps.length === 0) request()
  }, [scroller.key])

  return render({scroller, next})
}

export type SearchType = 'latest' | 'hot' | 'downloads' | 'plays' | 'rating'
export type SearchStatus = "all" | 0 | 1 | 2 | 3

interface ISearchProps {
  type: SearchType
  query?: string
  status?: SearchStatus
}

export type QueryType = 'text' | 'hash' | 'uploader'

interface IQueryProps {
  type: QueryType
  query: string
  status: SearchStatus
}

export type SearchTypes = SearchType | QueryType
export type IBeatmapSearch = ISearchProps | IQueryProps

const mapStateToProps: MapStateToProps<IConnectedProps,
  IPassedProps,
  IState> = (state, {type, query, status}) => {
  status = status !== undefined ? status : "all"
  // tslint:disable-next-line:max-line-length
  const key = query !== undefined ? `${type}?q=${query}&s=${status}` : (type !== "uploader" ? type : type + status as string)

  const defaultScroller: IScroller = {
    key,
    query,
    status,
    type,

    done: false,
    error: undefined,
    lastPage: null,
    loading: false,
    maps: [],
  }
  return {
    scroller: state.scrollers[key] || defaultScroller,
  }
}

const dispatchProps: IFunctionProps = {
  requestNextMaps: requestNextMapsFn,
}

const ConnectedBeatmapAPI = connect(mapStateToProps, dispatchProps)(BeatmapAPI)
export {ConnectedBeatmapAPI as BeatmapAPI}
