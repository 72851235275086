import { axios } from '../../utils/axios'
import { IUser, UserActionTypes } from './types'

type TypedThunk<P = any> = Thunk<UserActionTypes, P>

export type CheckUser = ThunkFunction<typeof checkUser>
export const checkUser: () => TypedThunk<IUser | null> = () => async dispatch => {
  try {
    const user = await axios.get<IUser>('/users/me')

    //user.data.admin = true

    dispatch({
      payload: user.data,
      type: UserActionTypes.SET_USER,
    })
  } catch (err) {
    dispatch({
      payload: null,
      type: UserActionTypes.SET_USER,
    })
  }
}

export type Login = ThunkFunction<typeof login>
export const login: (
  username: string,
  password: string
) => TypedThunk<IUser | null> = (username, password) => async (
  dispatch,
  getState
) => {
  await axios.post('/auth/login', { username, password })
  await checkUser()(dispatch, getState)
}

export type WxCheckLogin = ThunkFunction<typeof wxCheckLogin>
export const wxCheckLogin:() => TypedThunk<IUser | null> = () => async (
  dispatch,
  getState
) => {
  await axios.get('/auth/wx/login', { })
  await checkUser()(dispatch, getState)
}

// export type WxQRCode = ThunkFunction<typeof wxQRCode>
// export const wxQRCode:() => TypedThunk = () => async (
//   dispatch,
//   getState
// ) => {
//   console.error("=======")
//   const qrCodeUrl = await axios.get('/auth/wx/qrcode', { })
//   console.info('qrCodeUrl: ' + qrCodeUrl)
//   dispatch({
//     payload: qrCodeUrl,
//     type: UserActionTypes.SET_WXQRCODE,
//   })
// }

export type Register = ThunkFunction<typeof register>
export const register: (
  username: string,
  email: string,
  password: string
) => TypedThunk<IUser | null> = (username, email, password) => async (
  dispatch,
  getState
) => {
  await axios.post('/auth/register', { username, email, password })
  await checkUser()(dispatch, getState)
}

export type Logout = ThunkFunction<typeof logout>
export const logout: () => TypedThunk<null> = () => dispatch => {
  localStorage.removeItem('token')

  dispatch({
    payload: null,
    type: UserActionTypes.SET_USER,
  })
}
