import clsx from 'clsx'
import { RouterState } from "connected-react-router";
import React, { FunctionComponent, useState } from 'react'
import ReactModal from 'react-modal'
import { connect, MapStateToProps } from "react-redux";
import { Boundary } from './components/Boundary'
import { Footer } from './components/Footer'
import Navbar from './components/Navbar'
import { Routes } from './Routes'
import { MyWxLogin } from './routes/MyWxLogin'
import { IState } from './store'
import './utils/scroll'

interface IProps {
  router: RouterState
}

type IConnectedProps = IProps

const App: FunctionComponent<IProps> = ({router}) => {
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => {
    console.log('handleCloseModal')
    setShowModal(false)
  }
  const loginModalCallback = () => {
    console.log('loginModalCallback')
    setShowModal(true)
  }

  return (
    <>
      <Navbar loginModal={loginModalCallback}/>

      <ReactModal
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
        className='modal'
        onRequestClose={handleCloseModal}
        overlayClassName="overlay"
      >
        <div className='modal-content'>
          <div className='modal-header'>
            微信登录
            <div className='close' onClick={handleCloseModal}>X</div>
          </div>

          <div className='modal-body'>
            <div className='wx-login'>
              <MyWxLogin show={showModal}/>
            </div>
            <div className='description'>
              请使用微信扫描二维码登录
              <br/>
              “节奏空间BeatSaber”
            </div>
          </div>
        </div>
      </ReactModal>

      <div className={clsx('layout', router.location.pathname !== '/' && 'not-index')} id='layout'>
        <div className='container has-footer side-pad'>
          <Boundary>
            <Routes/>
          </Boundary>
        </div>

        <Footer/>
      </div>
    </>
  )
}

const mapStateToProps: MapStateToProps<IConnectedProps, {}, IState> = (state) => ({
  router: state.router
})

const ConnectedApp = connect(mapStateToProps)(App)

export { ConnectedApp as App }
