import { push as pushFn, RouterState } from 'connected-react-router'
import React, { FunctionComponent, MouseEvent, useState } from 'react'
import { connect, MapStateToProps } from 'react-redux'
import { IState } from '../../store'
import { IUser, Logout, logout as logoutFn } from '../../store/user'
import { NavbarDropdown2, NavbarDropdownDivider } from './NavbarDropdown'
import { NavbarClickableItem, NavbarItem } from './NavbarItem'

import clsx from 'clsx'
import Logo from '../../../images/logo.png'

interface ITabProps {
  selected: boolean
}

const TabItem: FunctionComponent<ITabProps> = ({ selected, children }) => {
  return (
    <div className="navbar-tab-item">
      {children}
      <hr className={clsx(selected && 'active')}/>
    </div>
  )
}

interface IPassedProps {
  loginModal: () => void
}

interface IConnectedProps {
  user: IUser | null | undefined
  router: RouterState
}

interface IDispatchedProps {
  logout: Logout
  push: typeof pushFn
}

type IProps = IPassedProps & IConnectedProps & IDispatchedProps

const Navbar: FunctionComponent<IProps> = ({ user, logout, push, router, loginModal }) => {
  const [active, setActive] = useState(false)

  const toggle = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setActive(!active)
  }

  const handleLogout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    push('/')
    logout()
  }

  const onWechatLoginClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (!user) {
      loginModal()
      return
    }
  }

  const avatarUrl = 'https://gravatar.com/avatar/8b3d8a961d4feccb6f5fae274d976ffc?s=400&d=robohash&r=x'

  return (
    <nav className={clsx('navbar fix-top', router.location.pathname !== '/' && 'not-index')}>
      <div className='container'>
        <div className='navbar-start'>
          <NavbarItem setActive={setActive} to='/'>
            <img src={Logo} alt='Beatsaberbbs'/>
          </NavbarItem>
        </div>

        <div className='navbar-tabs'>
          {/*<TabItem selected={router.location.pathname === '/'}>
            <NavbarItem setActive={setActive} to='/'>
              安装器
            </NavbarItem>
          </TabItem>*/}

          {/* tslint:disable-next-line:max-line-length */}
          <TabItem selected={router.location.pathname !== '/' && !router.location.pathname.includes('/legal/') && !router.location.pathname.includes('/search')}>
            <NavbarItem setActive={setActive} to='/browse/latest'>
              谱面库
            </NavbarItem>
          </TabItem>

          <TabItem selected={router.location.pathname === '/search'}>
            <NavbarItem setActive={setActive} to='/search'>
              搜索
            </NavbarItem>
          </TabItem>
        </div>

        <div className='navbar-end'>
          {!user ? (
            <>
              <NavbarItem setActive={setActive} to='' onClick={onWechatLoginClick}>
                <button>微信登录</button>
              </NavbarItem>
              <NavbarItem setActive={setActive} to='' onClick={onWechatLoginClick}>
                <button>上传谱面</button>
              </NavbarItem>
            </>
          ) : (
            <>
              <NavbarDropdown2 url={user.headimgurl}>
                <NavbarItem
                  setActive={setActive}
                  to={`/uploader/${user._id}/all`}
                >
                  我的谱面
                </NavbarItem>

                <NavbarDropdownDivider/>

                <NavbarClickableItem onClick={e => handleLogout(e)}>
                  退出登录
                </NavbarClickableItem>
              </NavbarDropdown2>
              <NavbarItem setActive={setActive} to='/user/upload'>
                <button>上传谱面</button>
              </NavbarItem>
            </>
          )}
        </div>
      </div>
    </nav>
  )
}

const mapStateToProps: MapStateToProps<IPassedProps & IConnectedProps, IPassedProps, IState> = (state, ownProps) => ({
  loginModal: ownProps.loginModal,
  router: state.router,
  user: state.user.login
})

const ConnectedNavbar = connect(mapStateToProps, {
  logout: logoutFn,
  push: pushFn,
})(Navbar)

export default ConnectedNavbar
