import Axios from "axios"
import {push as pushFn} from "connected-react-router"
import React, {FunctionComponent, useEffect, useState} from "react"
import Helmet from "react-helmet"
import {connect} from 'react-redux'
import {Loader} from "../components/Loader"
import {TextPage} from "../components/TextPage"
import {WxCheckLogin, wxCheckLogin as wxCheckLoginFun} from "../store/user"
import {axios} from '../utils/axios'

interface IProps {
  checkLogin: WxCheckLogin
  push: typeof pushFn
}

const WxLogin: FunctionComponent<IProps> = ({checkLogin, push}) => {
  const [signed, setSigned] = useState<boolean>(false)
  const [loadQrUrl, setLoadQrUrl] = useState<string | undefined | Error>("")
  const [timeoutSignal, setTimeoutSignal] = useState<boolean>(false)
  // tslint:disable-next-line:max-line-length
  const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Date.parse( new Date().getTime().toString())
  const loadQRCode = () => {
    const url = `https://vrservice.netvios.163.com/wechat/getQrcode?PlayerLoginPollToken=mod-` + randomString
    console.info("=== url = ", url)
    Axios.create()
      .post(url)
      .then(resp => {
        console.log(resp.data)
        const data = resp.data
        if (data.code === "000000") {
          setLoadQrUrl(data.data)
        } else {
          console.error(data.msg)
        }
      })
      .catch(err => {
        console.error(err)
        setLoadQrUrl(err)
      })
  }

  useEffect(() => {
    loadQRCode()

    let count = 0
    const interval = setInterval(() => {
      count++
      if (count > 30) {
        clearInterval(interval)
        setTimeoutSignal(true)
        setLoadQrUrl("")
        setSigned(false)
      } else {
        axios
          .get<ICheckWxLoginResponse>(`/auth/wx/checkwxlogin/${randomString}`)
          .then(resp => {
            console.info(resp.data)
            if (resp.data.signed) {
              setSigned(resp.data.signed)
              clearInterval(interval)
              push('/')
            }
          })
          .catch(err => {
            console.error(err)
          })
      }
    }, 1000)

    return () => {
      setLoadQrUrl("")
    }
  }, [])

  if (timeoutSignal) {
    return (
      <TextPage title='Timeout Error'>
        <p>QRCode Timeout! Please try again later.</p>
        <p>If the problem persists, please alert a site admin.</p>
      </TextPage>
    )
  }

  if (loadQrUrl === "") return <Loader/>
  if (loadQrUrl instanceof Error) {

    return (
      <TextPage title='Network Error'>
        <p>Failed to load QRCode info! Please try again later.</p>
        <p>If the problem persists, please alert a site admin.</p>
      </TextPage>
    )
  }

  return (
    <div className='wx-login'>
      <Helmet>
        <title>Beatsaberbbs - Login</title>
      </Helmet>
      <h1>
        Login
      </h1>
      {!loadQrUrl ? (
        "获取登录二维码失败，请刷再次新页面" +
        "loading..."
      ) : (
        <div>
          <iframe src={loadQrUrl.toString()} width='100%' height='600px;'></iframe>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps: IProps = {
  checkLogin: wxCheckLoginFun,
  push: pushFn,
}

const ConnectedWxLogin = connect(
  undefined,
  mapDispatchToProps
)(WxLogin)

export {ConnectedWxLogin as WxLogin}
