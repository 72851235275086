import React, {FunctionComponent} from 'react'
import {canUseDom} from '../../utils/dom'
import {BeatmapAPI, IBeatmapSearch} from './BeatmapAPI'
import {BeatmapGridScroller} from './BeatmapGridScroller'
import {BeatmapMyScroller} from './BeatmapMyScroller'

export const BeatmapList: FunctionComponent<IBeatmapSearch> = props => {
  const isFirefox = canUseDom()
    ? navigator.userAgent.toLowerCase().includes('firefox')
    : false
  if (props.type === "uploader") {
    return (
      <BeatmapAPI
        {...props}
        render={api => <BeatmapMyScroller {...api} finite={isFirefox}/>}
      />
    )
  } else if (props.type === 'latest') {
    return (
      <BeatmapAPI
        {...props}
        render={api => <BeatmapGridScroller {...api} finite={isFirefox}/>}
      />
    )
  } else {
    return (
      <BeatmapAPI
        {...props}
        render={api => <BeatmapGridScroller {...api} fallback={<></>} finite={isFirefox}/>}
      />
    )
  }
}
